.slide video {
    height: 100vh;
    width: 100vw;
    object-fit: cover;
    @media (max-width: 980px) {
        object-fit: contain;
    }
}

.slide-portrait {
    display: none;
}