* {
    margin: 0;
    padding: 0;
    border: none;
  }
  html,
  body {
    width: 100%;
    height: 100%;
    overflow: hidden;
    color: #fff;
    position: fixed;
    font-variant-ligatures: none;
    font-size: 100%;
    -webkit-text-size-adjust: none;
    -moz-text-size-adjust: none;
    -ms-text-size-adjust: none;
  }
  body {
    font-family: "CenturyGothicPro", Arial, sans-serif;
  }
  h1,
  h2,
  h3,
  h4 {
    font-family: "CenturyGothicProBold", Helvetica, Arial, sans-serif;
    font-weight: 700;
    font-variant-ligatures: none;
  }
  img {
    -ms-interpolation-mode: bicubic;
  }
  input,
  textarea {
    display: block;
    border: solid 1.2px #b7b7b7;
    font-family: "CenturyGothicPro", Arial, sans-serif;
    -webkit-appearance: none;
    -moz-appearance: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
  }
  input:placeholder,
  textarea:placeholder {
    color: #b7b7b7;
  }
  textarea {
    resize: none;
    overflow-y: scroll;
  }
  .mobile .only-desktop,
  .desktop .only-mobile {
    display: none;
  }
  .controls {
    z-index: 10;
  }
  .controls button {
    cursor: pointer;
    width: 60px;
    height: 60px;
    background-color: #000;
  }
  .controls button.left::after {
    display: inline-block;
    content: "";
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 8px solid #fff;
  }
  .controls button.right::after {
    display: inline-block;
    content: "";
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 8px solid #fff;
  }
  .controls button:focus {
    outline: none;
  }
  .controls-carousel {
    position: absolute;
    top: 32px;
    right: 34px;
  }
  .control.only-desktop {
    cursor: pointer;
    padding: 20px;
    background-color: #000;
    color: #fff;
    font-family: "CenturyGothicProBold", Helvetica, Arial, sans-serif;
    font-size: 21px;
  }
  .control.only-desktop:focus {
    outline: none;
  }
  .control.only-mobile {
    position: absolute;
    cursor: pointer;
  }
  .hidden {
    display: none;
  }
  @font-face {
    font-family: "CenturyGothicPro";
    src: url("../fonts/CenturyGothicPro.woff");
    font-display: swap;
  }
  @font-face {
    font-family: "CenturyGothicProBold";
    src: url("../fonts/CenturyGothicPro-Bold.woff");
    font-display: swap;
  }
  .navbar {
    -webkit-transition: background-color 0.1s ease;
    -moz-transition: background-color 0.1s ease;
    -o-transition: background-color 0.1s ease;
    -ms-transition: background-color 0.1s ease;
    transition: background-color 0.1s ease;
    height: 41px;
  }
  .navbar.shaded {
    background-color: #fff;
  }
  .navbar.fixed-top {
    position: fixed;
    width: 100vw;
    top: 0;
    left: 0;
    z-index: 1000;
  }
  .navbar-brand {
    position: absolute;
    left: 2.307%;
    height: 100%;
    width: 100px;
    background-image: url(../img/logo-b.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    z-index: 1;
  }
  @media only screen and (min-width: 1200px) {
    .navbar-brand {
      width: 180px;
    }
  }
  @media only screen and (min-width: 992px) {
    .navbar-brand {
      width: 140px;
    }
  }
  .hamburger {
    position: absolute;
    width: 3%;
    height: 100%;
    top: 0;
    right: 2.307%;
    cursor: pointer;
    transition: opacity 0.15s linear;
    background-color: transparent;
    min-width: 20px;
    max-width: 30px;
    z-index: 1;
  }
  .hamburger .hamburger-box {
    position: relative;
    display: block;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 100%;
    height: 17px;
  }
  .hamburger .hamburger-box span,
  .hamburger .hamburger-box span:after,
  .hamburger .hamburger-box span:before {
    display: block;
    content: "";
    position: absolute;
    width: 100%;
    height: 3px;
    transition: transform 0.15s ease;
    background-color: #000;
    -webkit-transition: background-color 1s ease-out;
    -moz-transition: background-color 1s ease-out;
    -o-transition: background-color 1s ease-out;
    -ms-transition: background-color 1s ease-out;
    transition: background-color 1s ease-out;
  }
  .hamburger .hamburger-box span {
    top: 50%;
    margin-top: -2px;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition-duration: 75ms;
  }
  .hamburger .hamburger-box span:before {
    top: -7px;
    transition: top 75ms ease 0.12s, opacity 75ms ease;
  }
  .hamburger .hamburger-box span:after {
    bottom: -7px;
    transition: bottom 75ms ease 0.12s,
      transform 75ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  .hamburger.black .hamburger-box span,
  .hamburger.black .hamburger-box span:after,
  .hamburger.black .hamburger-box span:before {
    background-color: #000;
  }
  .navbar.is-active .hamburger-box span,
  .navbar.is-active .hamburger-box span:after,
  .navbar.is-active .hamburger-box span:before {
    background-color: #fff;
  }
  .navbar-collapse {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    display: none;
  }
  .navbar.is-active .navbar-brand {
    background-image: url(../img/logo-w.svg);
  }
  .navbar.is-active .hamburger .hamburger-box span {
    transition-delay: 0.12s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: rotate(45deg);
  }
  .navbar.is-active .hamburger .hamburger-box span:before {
    top: 0;
    transition: top 75ms ease, opacity 75ms ease 0.12s;
    opacity: 0;
  }
  .navbar.is-active .hamburger .hamburger-box span:after {
    bottom: 0;
    transition: bottom 75ms ease,
      transform 75ms cubic-bezier(0.215, 0.61, 0.355, 1) 0.12s;
    transform: rotate(-90deg);
  }
  .navbar.is-active .navbar-collapse {
    display: block;
    width: 100vw;
    height: 100vh;
    background-color: #000;
  }
  .navbar.is-active .navbar-collapse .navbar-nav {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform-origin: top left;
    list-style: none;
  }
  .navbar.is-active .navbar-collapse .navbar-nav .nav-item {
    position: relative;
    white-space: nowrap;
    text-align: center;
    margin-bottom: 2vw;
  }
  .navbar.is-active .navbar-collapse .navbar-nav .nav-item.active .nav-link {
    color: #52ae32;
  }
  .navbar.is-active .navbar-collapse .navbar-nav .nav-item .nav-link {
    color: #fff;
    font-size: 10vw;
    text-decoration: none;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }
  .navbar.is-active.desktop .navbar-nav .nav-item.active::after {
    border-bottom-width: 0.5vh;
  }
  .navbar.is-active.desktop .navbar-nav .nav-item .nav-link {
    font-size: 6vh;
  }
  .mobile .navbar-brand {
    left: 4%;
  }
  .mobile .hamburger {
    width: 6.9%;
    right: 4%;
  }
  .fluid-img {
    max-width: 100%;
    height: auto;
  }
  .stage {
    position: relative;
    width: 100%;
    height: 100%;
  }
  #sections-indicators {
    position: absolute;
    top: 49vh;
    right: 2vw;
    list-style: none;
    -webkit-transition: opacity 1s ease;
    -moz-transition: opacity 1s ease;
    -o-transition: opacity 1s ease;
    -ms-transition: opacity 1s ease;
    transition: opacity 1s ease;
  }
  #sections-indicators > li {
    width: 8px;
    height: 8px;
    margin: 0 auto 8px;
    border-radius: 50%;
    background-color: #e6e6e6;
    transition: all 200ms ease-in-out;
    cursor: pointer;
  }
  #sections-indicators > li.active {
    -webkit-transform: scale(1.3);
    -moz-transform: scale(1.3);
    -o-transform: scale(1.3);
    -ms-transform: scale(1.3);
    transform: scale(1.3);
  }
  .slide {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
    z-index: 2;
  }
  .slide .slide-background > img {
    position: absolute;
    right: 0;
    top: 41px;
    transform-origin: top right;
    transform: scale(0);
  }
  .slide .slide-background.desktop > img {
    width: 78vw;
    height: auto;
  }
  .slide .slide-background.desktop > img:last-of-type {
    max-width: 25vw;
    height: auto;
  }
  .slide .slide-background.mobile > img {
    height: calc(100% - 41px);
    width: auto;
  }
  @media only screen and (min-width: 768px) {
    .slide .slide-background.mobile > img {
      height: 100vh;
    }
  }
  .slide .slide-background.mobile > img:last-of-type {
    max-height: 25vh;
    width: auto;
  }
  .slide .slide-caption {
    position: absolute;
    overflow: hidden;
  }
  .slide .slide-title {
    position: absolute;
    bottom: 0;
    left: 74vw;
    width: 0;
    height: 12vw;
    overflow: hidden;
    z-index: 1;
  }
  .slide .slide-title > img {
    position: absolute;
    bottom: 2vh;
    width: 24vw;
    height: auto;
  }
  .slide .slide-headline {
    font-family: "CenturyGothicPro", Arial, sans-serif;
    font-weight: 100;
  }
  .slide .slide-headline .squeezed {
    letter-spacing: -2px;
  }
  @media only screen and (min-width: 992px) {
    .slide .slide-headline .squeezed {
      letter-spacing: -4px;
    }
  }
  .slide .slide-headline > div {
    white-space: nowrap;
  }
  .slide .slide-copy > div {
    white-space: nowrap;
  }
  .slide .slide-portrait {
    position: absolute;
    bottom: 0;
    left: 0;
    max-height: 90vh;
    transform-origin: center;
  }
  .desktop .slide .slide-caption {
    top: 40vh;
    left: 41vw;
  }
  .desktop .slide .slide-caption .fixed-width {
    width: 50vw;
  }
  .desktop .slide .slide-caption .slide-headline {
    font-size: 3vw;
  }
  .desktop .slide .slide-caption .slide-copy {
    font-size: 1.4vw;
    line-height: 3vw;
  }
  .desktop .slide .slide-portrait {
    max-width: 60vw;
    max-height: calc(90% - 41px);
  }
  .mobile .slide .slide-title {
    left: 54vw;
    height: 22vw;
    bottom: 15px;
  }
  .mobile .slide .slide-title > img {
    width: 44vw;
  }
  .mobile .slide .slide-caption {
    top: 30vh;
    left: 10vw;
  }
  .mobile .slide .slide-caption .slide-headline {
    font-size: 5.8vw;
  }
  .mobile .slide .slide-caption .slide-headline:before {
    width: 8vw;
    margin-bottom: -1vw;
  }
  .mobile .slide .slide-caption .slide-headline:after {
    font-size: 10vw;
    margin-bottom: 6vw;
    margin-top: -2vw;
  }
  .mobile .slide .slide-caption .slide-copy {
    font-size: 3vw;
  }
  .mobile .slide .slide-portrait {
    max-height: 50vh;
    max-width: 131vw;
    bottom: -9.2vh;
  }
  @media only screen and (min-width: 710px) {
    .mobile .slide .slide-portrait {
      left: -72px;
      right: auto;
    }
  }
  .slide#viona .slide-portrait,
  .slide#mario .slide-portrait,
  .slide#saskia .slide-portrait,
  .slide#nadine .slide-portrait {
    -webkit-transform: translateX(-3%);
    -moz-transform: translateX(-3%);
    -o-transform: translateX(-3%);
    -ms-transform: translateX(-3%);
    transform: translateX(-3%);
  }
  .slide#kunal .slide-portrait {
    bottom: -10vh;
  }
  .section {
    position: absolute;
  }
  .section.section-intro .section-text .section-title,
  .section.section-intro .section-text .section-headline,
  .section.section-intro .section-text .section-copy {
    overflow-x: hidden;
  }
  .section.section-intro .section-text .section-title > div,
  .section.section-intro .section-text .section-headline > div,
  .section.section-intro .section-text .section-copy > div {
    white-space: nowrap;
  }
  .section.section-intro .section-text .section-title {
    position: absolute;
  }
  .section.section-intro .section-text .section-title img {
    height: 60px;
  }
  .section.section-intro .section-text .section-shapes {
    position: absolute;
    width: 23%;
  }
  .section.section-intro .section-text .section-shapes > img {
    position: absolute;
    transform-origin: top left;
    top: 0;
    left: 0;
  }
  .section.section-intro .section-text .section-shapes > img:first-of-type {
    width: 100%;
  }
  .section.section-intro .section-text .section-shapes > img:last-of-type {
    width: 50%;
  }
  .section.section-details .section-mobile {
    position: absolute;
    top: 0;
    left: 0;
  }
  .section.section-details h2 {
    white-space: nowrap;
    letter-spacing: -1.3px;
  }
  .section.section-details .form .error-msg {
    position: absolute;
    color: #f00;
  }
  .section.section-details .form input.error,
  .section.section-details .form textarea.error {
    border-color: #f00;
  }
  .section.section-details span.button,
  .section.section-details a.button {
    display: block;
    padding: 8px 30px;
    min-width: 100px;
    background-color: #ddd;
    color: #000;
    width: 40px;
    margin: 0 auto;
    text-align: center;
    cursor: pointer;
    text-decoration: none;
  }
  
  .section.section-details a.button {
    padding: 0;
  }
  
  .section.section-details span.li {
    position: relative;
    display: block;
    margin-left: 10px;
  }
  .section.section-details span.li:before {
    content: "";
    position: absolute;
    width: 4px;
    height: 4px;
    background-color: #333;
    display: inline-block;
    top: 6px;
    left: -10px;
    border-radius: 50%;
  }
  .mobile .control.only-mobile {
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    -ms-transition: all 1s ease;
    transition: all 1s ease;
  }
  .mobile .only-mobile.control-intro_details {
    border-top: solid 3px #fff;
    border-left: none;
    top: 48.5%;
    height: 4.5333vw;
    width: 4.5333vw;
    right: 2.6vw;
    z-index: 100;
    border-right: solid 3px #fff;
    transform-origin: center;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  .mobile .only-mobile.control-intro_details.active {
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    -ms-transform: rotate(-135deg);
    transform: rotate(-135deg);
    right: 0;
    border-color: #000;
  }
  .mobile .only-mobile.control-details_intro {
    border-top: solid 3px #000;
    top: 50%;
    height: 4.5333vw;
    width: 4.5333vw;
    left: 2.6vw;
    border-left: solid 3px #000;
    transform-origin: top left;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    z-index: 3;
  }
  .mobile .section-intro {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  .mobile .section-intro .section-picture,
  .mobile .section-intro .section-text {
    position: absolute;
    width: 100%;
  }
  .mobile .section-intro .section-picture {
    overflow: hidden;
    max-height: 50%;
  }
  .mobile .section-intro .section-picture > img {
    width: 100%;
    height: auto;
  }
  .mobile .section-intro .section-text {
    min-height: 50%;
  }
  .mobile .section-intro .section-text .section-shapes {
    width: 20%;
    top: auto;
    left: auto;
    bottom: 0;
    right: 0;
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  .mobile .section-intro .section-text .section-title {
    bottom: 2%;
    left: 7%;
  }
  .mobile .section-intro .section-text .section-headline {
    margin: 7% 0 3% 7%;
    font-size: 27px;
  }
  .mobile .section-intro .section-text .section-copy {
    margin-left: 7%;
    font-size: 16px;
  }
  .mobile .section-details {
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 100%;
  }
  .mobile .section-details .section-left,
  .mobile .section-details .section-right {
    display: none;
  }
  .mobile .section-details .section-mobile {
    width: 100%;
    height: 100%;
  }
  .mobile .section-details .section-mobile h2 {
    position: absolute;
    top: 18.14%;
    left: 11.333%;
    color: #000;
    font-size: 8.1vw;
    width: 80%;
    letter-spacing: -1.4px;
  }
  .mobile .section-details .section-mobile p {
    position: absolute;
    top: 24%;
    left: 11.333%;
    font-size: 3.7vw;
    width: 80%;
    color: #525252;
  }
  .mobile .section-details .section-mobile .section-form {
    position: absolute;
    width: 80%;
    left: 10%;
    top: 24%;
  }
  .mobile .section-details .section-mobile .section-form input,
  .mobile .section-details .section-mobile .section-form textarea {
    width: 100%;
    padding: 10px;
    font-size: 16px;
  }
  .mobile .section-details .section-mobile .section-form textarea {
    height: 80px;
  }
  .mobile .section-details .section-mobile .section-form .position-holder {
    position: relative;
    width: calc(90% + 22px);
    height: 37px;
  }
  .mobile .section-details .section-mobile .section-form .error-msg {
    line-height: 37px;
    font-size: 12px;
  }
  .mobile .section-details .section-mobile .section-form button {
    position: absolute;
    right: 0;
    display: block;
    padding: 12px;
    min-width: 100px;
    background-color: #ddd;
  }
  .mobile .reversed .control.only-desktop {
    right: auto;
    left: 0;
  }
  .mobile .reversed .section-intro .section-text .section-shapes {
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -o-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
  }
  .mobile .reversed .section-intro .section-text .section-shapes > img {
    transform-origin: bottom right;
    top: auto;
    left: auto;
    bottom: 0;
    right: 0;
  }
  .mobile .reversed .section-intro .section-text .section-title {
    left: 7%;
  }
  .mobile .reversed .section-intro .section-text .section-headline {
    margin: 7% 0 3% 7%;
  }
  .mobile .reversed .section-intro .section-text .section-copy {
    margin-left: 7%;
  }
  .mobile .reversed .section-details .section-mobile > img {
    transform-origin: bottom right;
    top: auto;
    left: auto;
    bottom: 0;
    right: 0;
  }
  .desktop .section {
    width: 1080px;
    height: 580px;
  }
  .desktop .control.only-desktop {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 0;
    font-size: 1.3vw;
    z-index: -1;
  }
  .desktop .only-desktop.control-details_intro {
    -webkit-transform: translateY(100%);
    -moz-transform: translateY(100%);
    -o-transform: translateY(100%);
    -ms-transform: translateY(100%);
    transform: translateY(100%);
  }
  .desktop .section-intro {
    display: block;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  .desktop .section-intro .section-picture,
  .desktop .section-intro .section-text {
    position: absolute;
    top: 100vh;
    width: 50%;
    height: 100%;
  }
  .desktop .section-intro .section-picture {
    right: 0;
    overflow: hidden;
  }
  .desktop .section-intro .section-picture > img {
    width: 100%;
    height: auto;
    transform-origin: center;
  }
  .desktop .section-intro .section-text {
    left: 0;
  }
  .desktop .section-intro .section-text .section-title {
    bottom: 1.7%;
    left: 6.5%;
  }
  .desktop .section-intro .section-text .section-headline {
    position: absolute;
    left: 7%;
    font-size: 3.3vw;
  }
  .desktop .section-intro .section-text .section-copy {
    position: absolute;
    left: 7%;
    font-size: 1.39vw;
  }
  .desktop .section-details {
    display: block;
    left: 150vw;
    top: 50%;
    color: #525252;
    font-size: 1.4vw;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  .desktop .section-details .section-mobile {
    display: none;
  }
  .desktop .section-details .section-left,
  .desktop .section-details .section-right {
    position: absolute;
    top: 0;
    width: 50%;
    height: 100%;
  }
  .desktop .section-details .section-left .section-shapes > img,
  .desktop .section-details .section-right .section-shapes > img {
    position: absolute;
    transform-origin: top left;
    top: 0;
    left: 0;
    z-index: 3;
  }
  .desktop .section-details .section-left .section-shapes > img:first-of-type,
  .desktop .section-details .section-right .section-shapes > img:first-of-type {
    width: 23%;
  }
  .desktop .section-details .section-left .section-shapes > img:last-of-type,
  .desktop .section-details .section-right .section-shapes > img:last-of-type {
    width: 11.5%;
  }
  .desktop .section-details .section-left {
    left: 0;
  }
  .desktop .section-details .section-right {
    right: 0;
  }
  .desktop .section-details h2 {
    position: absolute;
    top: 28%;
    left: 4%;
    color: #000;
    font-size: 3.3vw;
  }
  .desktop .section-details p {
    font-size: inherit;
    position: absolute;
    left: 4%;
    top: 40.6%;
    width: 92%;
    color: inherit;
  }
  .desktop .section-details input,
  .desktop .section-details textarea {
    width: 80%;
    padding: 1%;
    font-size: 1.4vw;
  }
  .desktop .section-details input {
    height: 3vw;
  }
  .desktop .section-details textarea {
    height: 13.1vw;
  }
  .desktop .section-details .form-group {
    position: absolute;
    top: 40.6%;
    left: 4%;
    width: 100%;
  }
  .desktop .section-details .form-group .error-msg {
    line-height: 2.8vw;
  }
  .desktop .section-details .form-group button {
    padding: 0.6vw 1vw;
    position: absolute;
    right: 18%;
  }
  .desktop .section-details span.button {
    position: absolute;
    bottom: 0;
    right: 0;
  }
  .desktop .section-details span.li {
    font-size: inherit;
  }
  .desktop .reversed .control.only-desktop {
    right: auto;
    left: 0;
  }
  .desktop .reversed .section-intro .section-picture {
    right: auto;
    left: 0;
  }
  .desktop .reversed .section-intro .section-text {
    left: auto;
    right: 0;
    background-position: bottom right;
  }
  .desktop .reversed .section-intro .section-text h1,
  .desktop .reversed .section-intro .section-text .section-title {
    top: 6%;
    bottom: auto;
  }
  .desktop .reversed .section-intro .section-text .section-shapes {
    top: auto;
    left: auto;
    bottom: 0;
    right: 0;
  }
  .desktop .reversed .section-intro .section-text .section-shapes > img {
    transform-origin: bottom right;
    top: auto;
    left: auto;
    bottom: 0;
    right: 0;
  }
  .desktop .reversed .section-details .section-left .section-shapes > img,
  .desktop .reversed .section-details .section-right .section-shapes > img {
    transform-origin: bottom right;
    top: auto;
    left: auto;
    bottom: 0;
    right: 0;
  }
  .desktop #intro .section-intro .section-text {
    background-color: #1a5d2b;
  }
  .desktop #intro .section-intro .section-text .section-headline {
    top: 33%;
  }
  .desktop #intro .section-intro .section-text .section-copy {
    top: 53%;
  }
  .mobile #intro .section-intro {
    display: block;
  }
  .mobile #intro .section-intro .section-text {
    background-color: #1a5d2b;
  }
  .desktop #aboutus .section-intro .section-text {
    background-color: #1a5d2b;
  }
  .desktop #aboutus .section-intro .section-text .section-headline {
    top: 33%;
  }
  .desktop #aboutus .section-intro .section-text .section-copy {
    top: 53%;
  }
  .mobile #aboutus .section-intro {
    display: block;
  }
  .mobile #aboutus .section-intro .section-text {
    background-color: #1a5d2b;
  }
  .desktop #ourpath .section-intro .section-text {
    background-color: #63b9e9;
  }
  .desktop #ourpath .section-intro .section-text .section-headline {
    top: 33%;
  }
  .desktop #ourpath .section-intro .section-text .section-copy {
    top: 53%;
  }
  .desktop #ourpath .section-details .section-left p,
  .desktop #ourpath .section-details .section-right p {
    letter-spacing: -0.3px;
  }
  .mobile #ourpath .section-intro {
    display: block;
  }
  .mobile #ourpath .section-intro .section-text {
    background-color: #63b9e9;
  }
  .desktop #career .section-intro .section-text {
    background-color: #50af31;
  }
  .desktop #career .section-intro .section-text .section-headline {
    top: 40.17%;
  }
  .desktop #career .section-intro .section-text .section-copy {
    top: 49.655%;
  }
  .desktop #career .section-details .section-right p {
    top: 25%;
  }
  .desktop #career .section-details .section-right .control {
    left: 0;
    margin-left: 15px;
    -webkit-transform: translateY(100%);
    -moz-transform: translateY(100%);
    -o-transform: translateY(100%);
    -ms-transform: translateY(100%);
    transform: translateY(100%);
    background-color: #ddd;
    color: #000;
  }
  .desktop #career .section-details .section-right .control {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    text-decoration: none;
  }
  .mobile #career .section-intro {
    display: block;
  }
  .mobile #career .section-intro .section-text {
    background-color: #50af31;
  }
  .mobile #career .section-mobile h2 {
    top: 10%;
  }
  .mobile #career .section-mobile p {
    top: 15.6%;
  }
  .mobile #career .section-mobile .li:first-of-type {
    margin-top: 10px;
  }
  #contact .section-thanks {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: #fff;
  }
  .desktop #contact .section-intro .section-text {
    background-color: #2d7bae;
  }
  .desktop
    #contact
    .section-intro
    .section-text
    .section-shapes
    > img:last-of-type {
    width: 70%;
  }
  .desktop #contact .section-intro .section-text .section-headline {
    top: 40.17%;
  }
  .desktop #contact .section-intro .section-text .section-copy {
    top: 49.655%;
  }
  .desktop #contact .section-details .section-shapes > img:last-of-type {
    width: 16%;
  }
  .desktop #contact .section-details .section-thanks > h2 {
    position: relative;
  }
  .mobile #contact .section-intro {
    display: block;
  }
  .mobile #contact .section-intro .section-text {
    background-color: #2d7bae;
  }
  .mobile
    #contact
    .section-intro
    .section-text
    .section-shapes
    > img:last-of-type {
    width: 70%;
  }
  .mobile #contact .section-details .section-right > img:last-of-type {
    width: 16%;
  }
  .mobile #contact .section-details .section-thanks > h2 {
    position: absolute;
    top: 18.14%;
    left: 11.333%;
    color: #000;
    width: 80%;
    letter-spacing: -1.4px;
  }
  @media only screen and (max-height: 400px) {
    .mobile #contact .section-mobile > h2 {
      position: relative;
      top: auto;
      margin-top: 60px;
    }
    .mobile #contact .section-mobile .section-form {
      position: relative;
      top: auto;
    }
  }
  footer {
    position: absolute;
    top: 100vh;
    width: 100%;
    height: 100%;
  }
  footer#offices {
    color: #000;
    background-color: #fff;
  }
  footer#imprint {
    color: #fff;
    background-color: #222;
  }
  footer .container-fluid {
    height: "100%";
  }
  footer .container-fluid .footer-group {
    position: relative;
    padding-top: 41px;
    width: 300px;
    height: 100vh;
    margin: 0 auto;
  }
  footer .container-fluid .footer-group .footer-title {
    margin-top: 10vh;
    text-align: center;
  }
  footer .container-fluid .footer-group .footer-title > img {
    height: 60px;
  }
  @media only screen and (min-width: 992px) {
    footer .container-fluid .footer-group .footer-title {
      text-align: left;
    }
    footer .container-fluid .footer-group .footer-title > img {
      height: 76px;
    }
  }
  footer .container-fluid .footer-group .footer-item {
    display: block;
    width: 127px;
    margin: 0 auto 20px auto;
    text-align: left;
  }
  footer .container-fluid .footer-group .footer-item > a {
    text-decoration: none;
  }
  footer .container-fluid .footer-group .footer-textblock {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    text-align: center;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  footer .container-fluid .footer-group .footer-textblock > p {
    white-space: normal;
    padding: 10px 0;
  }
  footer .container-fluid h6 {
    font-size: 16px;
    text-transform: capitalize;
    color: #919191;
    font-weight: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: antialiased;
  }
  @media only screen and (min-width: 992px) {
    footer .container-fluid h6 {
      font-size: 20px;
    }
  }
  footer .container-fluid p {
    white-space: nowrap;
    font-size: 13px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: antialiased;
  }
  @media only screen and (min-width: 992px) {
    footer .container-fluid p {
      font-size: 18px;
    }
  }
  footer .container-fluid a {
    color: inherit;
  }
  footer .container-copyright {
    position: absolute;
    height: 54px;
    width: 100%;
    bottom: 0;
    background-color: #333;
  }
  footer .container-copyright .footer-group {
    width: 49%;
    display: inline-block;
    white-space: nowrap;
  }
  footer .container-copyright .footer-group:last-of-type {
    text-align: right;
  }
  footer .container-copyright .copyright {
    display: inline-block;
    color: #ddd;
    font-size: 10px;
    margin-top: 21px;
    margin-left: 25%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: antialiased;
  }
  footer .container-copyright .nav {
    margin-right: 25%;
  }
  footer .container-copyright .nav .nav-item {
    list-style: none;
    display: inline-block;
    white-space: nowrap;
    padding: 20px 16px 20px 0;
  }
  footer .container-copyright .nav .nav-item .nav-link {
    color: #ddd;
    font-size: 11px;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: antialiased;
  }
  .desktop footer .container-fluid .footer-group {
    width: 440px;
  }
  @media only screen and (min-width: 768px) {
    .desktop footer .container-fluid .footer-group {
      width: 512px;
    }
  }
  @media only screen and (min-width: 992px) {
    .desktop footer .container-fluid .footer-group {
      width: 792px;
    }
  }
  @media only screen and (min-width: 1200px) {
    .desktop footer .container-fluid .footer-group {
      width: 1024px;
    }
  }
  .desktop footer .container-fluid .footer-group .footer-item {
    display: inline-block;
    vertical-align: top;
    margin-right: calc((100% - (130px * 3)) / 2);
  }
  .desktop footer .container-fluid .footer-group .footer-item:last-of-type {
    margin-right: 0;
  }
  
@import 'new'; 